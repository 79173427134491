import { useQuery } from 'react-query';
import { getAuth } from '@firebase/auth';

const { REACT_APP_API_URL: API_URL } = process.env;

const fetchInsurers = async () => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/insurers`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchInsurers] ${data.error}`);

	return data;
};

const fetchInsurerData = async insurerId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/insurers/${insurerId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchInsurerData] ${data.error}`);

	return data;
};

const fetchStatuses = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/file-status/${orderId}`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[fetchStatuses] ${data.error}`);

	return data;
};

export const useFetchInsurers = (options = {}) => {
	return useQuery(['insurers'], () => fetchInsurers(), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useFetchInsurerData = (insurerId, options = {}) => {
	return useQuery(['insurers', insurerId], () => fetchInsurerData(insurerId), {
		staleTime: 5 * 60 * 1000,
		retry: false,
		refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

export const useFetchStatuses = (orderId, options = {}) => {
	return useQuery(['statuses', orderId], () => fetchStatuses(orderId), {
		// staleTime: 5 * 60 * 1000,
		retry: false,
		// refetchOnMount: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};

const fetchScanariDosar = async orderId => {
	const token = await getAuth().currentUser.getIdToken();

	const res = await fetch(`${API_URL}/documents/${orderId}?type=scanari-dosar`, {
		headers: { authorization: `Bearer ${token}` },
	});
	const data = await res.json();

	if (data.error) throw new Error(`[${fetchScanariDosar.name}] ${data.error}`);

	return data;
};

export const useFetchScanariDosar = (orderId, options = {}) => {
	return useQuery(['scanari-dosar', orderId], () => fetchScanariDosar(orderId), {
		staleTime: 1 * 60 * 1000,
		retry: false,
		refetchOnReconnect: false,
		refetchOnWindowFocus: false,
		...options,
	});
};
