import { Flex, Link, Text } from '@chakra-ui/react';
import { saveAs } from 'file-saver';
import React from 'react';
import { useParams } from 'react-router-dom';
import { getAuth } from '@firebase/auth';
import { formatDate } from '../../utils/helpers';

const { REACT_APP_API_URL: API_URL } = process.env;

export const DosarItem = ({ filename }) => {
	const { orderId } = useParams();

	const date = filename.split('_')[0];
	// console.log({ date, filename });

	const handleDownload = async () => {
		try {
			const token = await getAuth().currentUser.getIdToken();
			const res = await fetch(`${API_URL}/documents/${orderId}/download/${filename}?type=scanari-dosar`, {
				headers: { authorization: `Bearer ${token}` },
			});
			const data = await res.blob();
			saveAs(data, filename);
		} catch (error) {
			console.log({ error });
		}
	};

	return (
		<Flex
			borderWidth={'thin'}
			borderRadius={'md'}
			bgColor={'whiteAlpha.50'}
			padding={4}
			mb={3}
			justifyContent={'space-between'}
			alignItems={'center'}
		>
			<Text size="md" maxW={'50%'}>
				{filename}
			</Text>
			{filename === 'no-file' ? null : (
				<Link color={'blue.200'} onClick={handleDownload}>
					Descarca
				</Link>
			)}
			<Text>
				Incarcat la <strong>{formatDate(new Date(+date), undefined, 'Europe/Bucharest')}</strong>
			</Text>
		</Flex>
	);
};
