import React from 'react';
import { Box, Button, Text } from '@chakra-ui/react';
import { useDropzone } from 'react-dropzone';
import { useQueryClient } from 'react-query';
import { useUploadDocuments } from '../../state/server-side/mutations/documents-status';

// ... existing code ...
export const UploadZone = ({ orderId }) => {
	const queryClient = useQueryClient();
	const [selectedFiles, setSelectedFiles] = React.useState([]);

	const uploadMutation = useUploadDocuments();

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop: acceptedFiles => {
			setSelectedFiles(prev => [...prev, ...acceptedFiles]);
		},
	});

	const handleUpload = () => {
		uploadMutation.mutate(
			{ orderId, type: 'scanari-dosar', files: selectedFiles },
			{
				onSuccess: () => {
					setSelectedFiles([]);
					queryClient.invalidateQueries(['scanari-dosar', orderId]);
				},
			}
		);
	};

	const removeFile = fileIndex => {
		setSelectedFiles(prev => prev.filter((_, index) => index !== fileIndex));
	};

	return (
		<>
			<Box
				{...getRootProps()}
				p={6}
				mb={4}
				border="2px dashed"
				borderColor={isDragActive ? 'blue.400' : 'gray.200'}
				borderRadius="md"
				textAlign="center"
				cursor="pointer"
				_hover={{ borderColor: 'blue.400' }}
			>
				<input {...getInputProps()} />
				<Text>{isDragActive ? 'Lasa fisierele aici' : 'Trage fisiere aici, sau click pentru a le selecta'}</Text>
			</Box>

			{selectedFiles.length > 0 && (
				<Box mb={4}>
					{selectedFiles.map((file, index) => (
						<Box key={index} display="flex" alignItems="center" justifyContent="space-between" mb={2}>
							<Text>{file.name}</Text>
							<Text
								cursor="pointer"
								color="red.500"
								onClick={() => removeFile(index)}
								_hover={{ textDecoration: 'underline' }}
							>
								Remove
							</Text>
						</Box>
					))}
					<Button
						colorScheme="blue"
						isLoading={uploadMutation.isLoading}
						onClick={handleUpload}
						isDisabled={selectedFiles.length === 0}
					>
						Upload
					</Button>
				</Box>
			)}

			{uploadMutation.isError && <Text color="red.500">Upload failed. Please try again.</Text>}
		</>
	);
};
