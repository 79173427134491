import { Box, Flex } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import BackButton from '../../components/common/basic/back-button';
import DownloadFilesButton from '../file-status/download-button';
import { useFetchScanariDosar } from '../../state/server-side/queries/documents-status';
import { useFetchOrder } from '../../state/server-side/queries/order';
import OrderDetails from '../../components/common/order-details';
import { DosarItem } from './dosar-item';
import { UploadZone } from './upload';

export const ScanariDosarPage = () => {
	const navigate = useNavigate();
	const { orderId } = useParams();
	const { data: order, isLoading: isOrderLoading } = useFetchOrder(orderId);
	const { data, isLoading } = useFetchScanariDosar(orderId);

	const handleBack = () => {
		navigate(-1);
	};

	if (isLoading || isOrderLoading) return <div>Loading...</div>;

	return (
		<>
			<Box d="flex">
				<Box mt={6} px={2} d={'flex'} w="80%" alignItems="center" justifyContent="flex-start">
					<BackButton onClick={handleBack}>Inapoi</BackButton>
					{/* TODO: adapt to scanari dosar */}
					<DownloadFilesButton orderId={orderId} />
				</Box>
				<OrderDetails data={order} />
			</Box>
			<Flex mt={4} width={'100%'} justifyContent={'center'}>
				<Box width={'80%'}>
					<UploadZone orderId={orderId} />
					{data?.length > 0 ? (
						data.map(item => <DosarItem key={item.id} filename={item.filename} />)
					) : (
						<div>Nu am gasit scanari pentru acest deviz.</div>
					)}
				</Box>
			</Flex>
		</>
	);
};
