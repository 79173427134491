import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import BackButton from '../components/common/basic/back-button';
import MainLayout from '../components/common/main-layout';

const PhotosPage = () => {
	const navigate = useNavigate();
	const { orderId } = useParams();

	const handleReceptie = () => {
		navigate(`/${orderId}/gallery/receptie`);
	};

	const handleSuplimentari = () => {
		navigate(`/${orderId}/gallery/suplimentari`);
	};

	const handleCurtoazie = () => {
		navigate(`/${orderId}/gallery/curtoazie`);
	};

	const handleFileStatus = () => {
		navigate(`/${orderId}/file-status`);
	};

	const handleDosarScanat = () => {
		navigate(`/${orderId}/scanari-dosar`);
	};

	const handleBack = () => {
		navigate(`/${orderId}`);
	};

	return (
		<MainLayout flexDirection={'column'}>
			<Flex wrap={'wrap'} justify={'space-between'}>
				<Button
					h={{ base: '75px', md: '125px' }}
					minW="30%"
					flexGrow={1}
					m={2}
					colorScheme={'blue'}
					flexDirection={'column'}
					onClick={handleReceptie}
				>
					<Text color={'gray.700'}>GALERIE</Text>
					<Heading>Receptie</Heading>
				</Button>
				<Button
					h={{ base: '75px', md: '125px' }}
					minW="30%"
					flexGrow={1}
					m={2}
					colorScheme={'blue'}
					flexDirection={'column'}
					onClick={handleSuplimentari}
				>
					<Text color={'gray.700'}>GALERIE</Text>
					<Heading>Suplimentari</Heading>
				</Button>
				<Button
					h={{ base: '75px', md: '125px' }}
					minW="30%"
					flexGrow={1}
					m={2}
					colorScheme={'blue'}
					flexDirection={'column'}
					onClick={handleCurtoazie}
				>
					<Text color={'gray.700'}>GALERIE</Text>
					<Heading>Curtoazie</Heading>
				</Button>
				<Button
					h={{ base: '75px', md: '125px' }}
					minW="30%"
					flexGrow={1}
					m={2}
					colorScheme={'blue'}
					flexDirection={'column'}
					onClick={handleFileStatus}
				>
					<Text color={'gray.700'}>INFO DEVIZ</Text>
					<Heading>Status dosar</Heading>
				</Button>
				<Button
					h={{ base: '75px', md: '125px' }}
					minW="30%"
					flexGrow={1}
					m={2}
					colorScheme={'blue'}
					flexDirection={'column'}
					onClick={handleDosarScanat}
				>
					<Text color={'gray.700'}>INFO DEVIZ</Text>
					<Heading>Scanari dosar</Heading>
				</Button>
			</Flex>
			<BackButton mt={6} minW={'400px'} onClick={handleBack}>
				Inapoi
			</BackButton>
		</MainLayout>
	);
};

export default PhotosPage;
